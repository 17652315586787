import * as React from 'react';
import { Component } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom"
import { red, green, blue } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Virtuoso } from 'react-virtuoso'

import Avatar from '@mui/material/Avatar';
import PieGraph from '../components/pieGraph';
import BaseGraph from '../components/baseGraph';
import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RowDomanda } from '../components/riepilogoQuizSvolti/rowDomanda';


export class RiepilogoQuizSvolti extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isInRiepilogo: this.props?.isInRiepilogo ?? false,
            utenteID: this.props.idUtente,
            isLoading: true,
            listQuiz: [],
            listQuestionari: [],
            viewingListQuiz: false,
            nomeQuestionario: '',
            risposteCorrette: 0,
            punteggio: -1,
            typeQuestionario: "Sim",
            risposteErrate: 0,
            risposteOmesse: 0,
            dataQuestionario: undefined,
            questionarioSelezionato: this.props.questionarioID,
            dateQuestionari: [],
            esitiQuestionari: [],
            tempoCompletamento: "",
            esito: true,
        }
    }

    componentDidMount = () => {
        this.getAllQuestionari();
    }
    getAllQuestionari = () => {
        let utenteID = this.state.utenteID;
        if (localStorage.getItem("id") != null)
            utenteID = localStorage.getItem("id")
        let requestOptions = {
            method: 'POST',
            body: new URLSearchParams({
                id_utente: utenteID,
                id_questionario: this.state.questionarioSelezionato
            })
        };

        fetch(process.env.REACT_APP_FETCH_URL + 'questionario/archive/retrive', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success === true) {

                    let tmpEsitiQuestionari = [];
                    let tmpDateQuestionari = [];
                    for (let i = 0; i < result.data.length; i++) {
                        tmpEsitiQuestionari.push(result.data[i].json.risposteCorrette)
                        let dataTmp = this.convertDateFormat(result.data[i].data)
                        dataTmp = dataTmp.slice(0, 5) //GG/MM
                        tmpDateQuestionari.push(dataTmp)
                    }

                    let reversedQuestionari = result.data.sort((a, b) => {
                        if (a.id > b.id) {
                            return -1;
                        }
                        if (a.id < b.id) {
                            return 1;
                        }
                        return 0;
                    });
                    // result.data.reverse();
                    this.setState({
                        isLoading: false,
                        listQuestionari: reversedQuestionari,
                        dateQuestionari: tmpDateQuestionari,
                        esitiQuestionari: tmpEsitiQuestionari

                    })
                }
                else {
                    this.setState({
                        isLoading: false
                    });
                }
                if (result.status === 403) {
                    window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
                }
            });


    }
    visualizzaRiepilogoQuiz = (questionario) => {
        document.getElementById('parent').parentNode.scrollTo({ top: 0, behavior: 'smooth' })
        let dataTmp = this.convertDateFormat(questionario.data)
        let risposteCorretteTmp = questionario?.json?.risposteCorrette ?? 0;
        let risposteOmesseTmp = questionario?.json?.risposteOmesse ?? 0;
        let risposteErrateTmp = questionario?.json?.risposteErrate ?? 0;

        // for (let i = 0; i < questionario.json.quiz.length; i++) {
        //     if (questionario.json.quiz[i].givenAnswer === questionario.json.quiz[i].correctAnswer)
        //         risposteCorretteTmp += 1;
        //     if (questionario.json.quiz[i].givenAnswer === '')
        //         risposteOmesseTmp += 1;
        //     if (questionario.json.quiz[i].givenAnswer !== questionario.json.quiz[i].correctAnswer && questionario.json.quiz[i].givenAnswer !== "") {
        //         risposteErrateTmp += 1;
        //     }
        // }

        let dataInizioIso = questionario.json.dataInizio ?? "";
        let dataFineIso = questionario.json.dataFine ?? "";
        let tempoCompletamentoTmp = this.convertTempoDiCompletamento(dataInizioIso, dataFineIso);
        this.setState({
            nomeQuestionario: questionario.json.nomeQuestionario,
            listQuiz: questionario.json.quiz,
            viewingListQuiz: true,
            punteggio: questionario?.json?.punteggio ?? -1,
            risposteCorrette: risposteCorretteTmp,
            risposteErrate: risposteErrateTmp,
            risposteOmesse: risposteOmesseTmp,
            dataQuestionario: dataTmp,
            typeQuestionario: questionario?.json?.type ?? "Sim",
            esito: questionario?.json?.superato
        })
    }
    goBackToRiepilogo = () => {
        this.setState({
            viewingListQuiz: false
        })
    }
    closeDialog = () => {
        this.props.onClose();
    }
    rowQuestionarioSvolto = (index) => {
        return (
            <Box px={{ sx: 0, md: 3 }} sx={{ mt: 2 }}>
                <Grid container spacing={1} >
                    <Grid item xs={4} md={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography display={{ xs: 'none', md: 'flex' }}>
                            {this.convertDateFormat(this.state.listQuestionari[index].data)}
                        </Typography>
                        <Typography variant="body2" display={{ xs: 'flex', md: 'none' }}>
                            {this.convertDateFormat(this.state.listQuestionari[index].data)}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} display={{ xs: 'none', md: 'flex' }} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        {this.state.listQuestionari[index].json.type == "Sim" ? (
                            <Chip label={this.state.listQuestionari[index].json.superato === true ? 'SUPERATO' : 'NON SUPERATO'} color={this.state.listQuestionari[index].json.superato === true ? 'primary' : 'error'}
                                variant="contained" />
                        ) : (
                            <Chip label="ESERCITAZIONE" color={"default"}
                                variant="contained" />
                        )}
                    </Grid>
                    <Grid item xs={4} display={{ xs: 'flex', md: 'none' }} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        {this.state.listQuestionari[index].json.type == "Sim" ? (
                            <Chip label={this.state.listQuestionari[index].json.superato === true ? 'SI' : 'NO'} color={this.state.listQuestionari[index].json.superato === true ? 'primary' : 'error'}
                                variant="contained" />
                        ) : (
                            <Chip label="ESE" color={"default"}
                                variant="contained" />
                        )}
                    </Grid>

                    <Grid item xs={2} flexDirection={"column"} justifyContent={'start'} display={{ xs: 'none', md: 'flex' }} >
                        <Typography textAlign={"start"} variant="body1" sx={{ color: 'green', width: '100%' }}>
                            <span>Risposte corrette: </span>
                            <b>
                                {this.state.listQuestionari[index].json !== undefined && (
                                    this.state.listQuestionari[index].json.risposteCorrette
                                )}
                            </b>
                        </Typography>
                        <Typography textAlign={"start"} variant="body1" sx={{ color: 'red', width: '100%' }}>
                            <span>Risposte errate: </span>
                            <b>
                                {this.state.listQuestionari[index].json !== undefined && (
                                    this.state.listQuestionari[index].json.risposteErrate
                                )}
                            </b>
                        </Typography>
                        <Typography textAlign={"start"} variant="body1" sx={{ width: '100%' }}>
                            <span>Risposte omesse: </span>
                            <b>
                                {this.state.listQuestionari[index].json !== undefined && (
                                    this.state.listQuestionari[index].json.risposteOmesse
                                )}
                            </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} flexDirection={"column"} justifyContent={'start'} display={{ xs: 'none', md: 'flex' }} >
                        <Typography textAlign={"start"} variant="body1" sx={{ width: '100%' }}>
                            <span>Ora inizio: </span>
                            {this.state.listQuestionari[index]?.json?.dataInizio !== undefined ? (
                                <b>
                                    {this.convertToHours(this.state.listQuestionari[index]?.json?.dataInizio)}
                                </b>
                            ) : (<b>-</b>)}
                        </Typography>
                        <Typography textAlign={"start"} variant="body1" sx={{ width: '100%' }}>
                            <span>Ora fine: </span>
                            {this.state.listQuestionari[index]?.json?.dataFine !== undefined ? (
                                <b>
                                    {this.convertToHours(this.state.listQuestionari[index]?.json?.dataFine)}
                                </b>
                            ) : (<b>-</b>)}
                        </Typography>
                        <Typography textAlign={"start"} variant="body1" sx={{ width: '100%' }}>
                            <span>Completato in: </span>
                            {this.state.listQuestionari[index]?.json?.dataInizio !== undefined && this.state.listQuestionari[index]?.json?.dataFine !== undefined ? (
                                <b>
                                    {this.convertTempoDiCompletamento(this.state.listQuestionari[index]?.json?.dataInizio, this.state.listQuestionari[index]?.json?.dataFine)}
                                </b>
                            ) : (<b>-</b>)}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} display={{ xs: 'none', md: 'flex' }} alignItems={"center"}>
    <Typography variant="h6" sx={{ textAlign: 'center', width: '100%' }}>
        {this.state.listQuestionari[index]?.json?.punteggio !== undefined && this.state.listQuestionari[index]?.json?.type !== "Ese" ? (
            parseFloat(this.state.listQuestionari[index]?.json?.punteggio).toFixed(3).replace(/\.?0+$/, '')
        ) : (<span>-</span>)}
    </Typography>
</Grid>




                    <Grid item xs={4} md={2} display={{ xs: 'none', md: 'flex' }} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Button sx={{ width: '100%', height: 'fit-content' }} variant="outlined" onClick={() => this.visualizzaRiepilogoQuiz(this.state.listQuestionari[index])}>
                            Visualizza
                        </Button>
                    </Grid>
                    <Grid item xs={4} md={2} display={{ xs: 'flex', md: 'none' }} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Button size="small" sx={{ width: '100%', height: 'fit-content' }} variant="outlined" onClick={() => this.visualizzaRiepilogoQuiz(this.state.listQuestionari[index])}>
                            Visualizza
                        </Button>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 2 }} />
            </Box>
        )
    }
    convertDateFormat = (date) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        let d = new Date(date)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }

    convertToHours = (dateString) => {
        if (dateString !== undefined) {
            var date = new Date(dateString);
            var day = ("0" + date.getDate()).slice(-2);
            var month = ("0" + (date.getMonth() + 1)).slice(-2);
            var year = date.getFullYear();
            var hours = ("0" + date.getHours()).slice(-2);
            var minutes = ("0" + date.getMinutes()).slice(-2);

            return hours + ":" + minutes;
        } else return ""
    }
    convertTempoDiCompletamento = (dataInizio, dataFine) => {
        let dataInizioIso = dataInizio;//questionario.json.dataInizio ?? "";
        let dataFineIso = dataFine; // questionario.json.dataFine ?? "";
        let tempoCompletamentoTmp = "";

        if (dataInizioIso !== "" && dataFineIso !== "") {
            let timestamp1 = Date.parse(dataInizioIso)
            let timestamp2 = Date.parse(dataFineIso)

            let diffMilliseconds = Math.max(0, timestamp2 - timestamp1);

            // Convert to hours, minutes, and seconds
            let diffSeconds = Math.floor(diffMilliseconds / 1000);
            let diffMinutes = Math.floor(diffSeconds / 60);
            let diffHours = Math.floor(diffMinutes / 60);

            diffSeconds = (diffSeconds % 60).toString().padStart(2, '0');
            diffMinutes = (diffMinutes % 60).toString().padStart(2, '0');

            tempoCompletamentoTmp = diffHours.toString() + ":" + diffMinutes.toString() + ":" + diffSeconds.toString();
            return tempoCompletamentoTmp
        } else return " - "
    }


    changeColorAnswerText = (domanda, choice) => {
        if (Array.isArray(domanda.givenAnswer)) {
            if (domanda.givenAnswer.includes(choice.value)) { //Se è un array
                if (!domanda.correctAnswer.includes(choice.value)) {
                    return red[600]
                }
            }
        } else {
            if (choice.value === domanda.givenAnswer) { //Se è una stringa
                if (!domanda.correctAnswer.includes(choice.value)) {
                    return red[600]
                }
            }
        }

        if (domanda.correctAnswer.includes(choice.value))
            return green[600]
    }


    render() {
        return (
            <Box id="parent">

                {this.state.viewingListQuiz === false && (
                    <Box >
                        {/* {this.state.isInRiepilogo === false && (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ textAlign: 'center' }} variant="h3" >Riepilogo test svolti in precedenza</Typography>
                                <IconButton onClick={this.closeDialog}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        )} */}
                        <Box sx={{ my: 2 }}>
                            <BaseGraph
                                chartLabels={this.state.dateQuestionari}
                                chartData={[
                                    {
                                        name: 'Risposte corrette',
                                        type: 'area',
                                        fill: 'gradient',
                                        data: this.state.esitiQuestionari,
                                    }
                                ]}
                            />
                        </Box>


                        <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <Grid item xs={4} md={2} sx={{ backgroundColor: "#00337024", color: "#003370", borderRadius: "10px 0px 0px 10px" }}>
                                <Typography sx={{ pb: 1, pl: 2, textAlign: 'center' }} variant="subtitle2">Data</Typography>
                            </Grid>
                            <Grid item xs={2} display={{ xs: 'none', md: 'flex' }} sx={{ textAlign: 'center', backgroundColor: "#00337024", color: "#003370" }}>
                                <Typography sx={{ pb: 1, width: '100%' }} variant="subtitle2">Esito</Typography>
                            </Grid>
                            <Grid item xs={4} display={{ xs: 'flex', md: 'none' }} sx={{ justifyContent: 'center', backgroundColor: "#00337024", color: "#003370" }}>
                                <Typography sx={{ pb: 1, }} variant="subtitle2">Superato</Typography>
                            </Grid>
                            <Grid item xs={4} display={{ xs: 'none', md: 'flex' }} sx={{ textAlign: 'center', backgroundColor: "#00337024", color: "#003370" }}>
                                <Typography sx={{ pb: 1, }} variant="subtitle2">&#8203;</Typography>
                            </Grid>
                            {/* <Grid item xs={2} display={{ xs: 'none', md: 'flex' }} sx={{ textAlign: 'center', backgroundColor: "#00337024", color: "#003370" }}>
                                <Typography sx={{ pb: 1, width: '100%' }} variant="subtitle2">Errate</Typography>
                            </Grid> */}
                            <Grid item xs={2} display={{ xs: 'none', md: 'flex' }} sx={{ textAlign: 'center', backgroundColor: "#00337024", color: "#003370" }}>
                                <Typography sx={{ pb: 1, width: '100%' }} variant="subtitle2">Punteggio</Typography>
                            </Grid>
                            <Grid item xs={4} md={2} sx={{ textAlign: 'center', backgroundColor: "#00337024", color: "#003370", borderRadius: "0px 10px 10px 0px" }}>
                                <Typography sx={{ pb: 1, }} variant="subtitle2">&#8203;</Typography>

                            </Grid>
                        </Grid>
                        {this.state.isLoading === true ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            this.state.listQuestionari.length > 0 ? (
                                <Box sx={{ height: '50vh', border: '1px solid #00000021', padding: '10px', borderRadius: '10px' }}>
                                    <Virtuoso
                                        style={{ height: '100%' }}
                                        totalCount={this.state.listQuestionari.length}
                                        itemContent={index => (this.rowQuestionarioSvolto(index))}
                                    />
                                </Box>

                            ) : <Typography sx={{ my: 5, textAlign: 'center' }} variant="h5">
                                Non è stato svolto nessun simulatore in precedenza.
                            </Typography>
                        )}

                    </Box>
                )}
                {this.state.viewingListQuiz === true && (
                    <Box >
                        {/* <Button variant="contained" className='goToTopButton' ><ArrowUpwardIcon /></Button> */}
                        <Box sx={{ width: '100%', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'end', alignItems: 'center', mt: 2 }}>
                            {this.state.viewingListQuiz === true && (
                                <Button variant="outlined" sx={{ width: { xs: '100%', md: 'fit-content' }, mr: { xs: 0, md: 2 } }} startIcon={<ArrowBackIcon />} onClick={this.goBackToRiepilogo}>Torna al riepilogo</Button>
                            )}
                            {this.state.isInRiepilogo === false && (
                                <Button variant="contained" sx={{ width: { xs: '100%', md: 'fit-content' }, mt: { xs: 1, md: 0 } }} onClick={this.closeDialog}>Chiudi</Button>
                            )}
                        </Box>

                        <Paper elevation={4} sx={{ p: { xs: 1, md: 5 }, mt: 2 }}>
                            <Grid container spacing={1}>

                                <Grid container item xs={12} md={6}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">{this.state.nomeQuestionario}</Typography>
                                        <Typography variant="body2" >Data svolgimento: <b>{this.state.dataQuestionario}</b></Typography>
                                        {this.state.punteggio !== -1 && this.state.typeQuestionario !== "Ese" && (
                                            <Typography variant="body2">Punteggio totale: <b>{this.state.punteggio}</b></Typography>
                                        )}
                                        {this.state.tempoCompletamento !== "" && (
                                            <Typography variant="body2" sx={{ mb: 2 }} >Tempo di completamento svolgimento: <b>{this.state.tempoCompletamento}</b></Typography>
                                        )}

                                        <Box sx={{ mt: 2 }}>
                                            {this.state.typeQuestionario === "Sim" && (
                                                <Chip label={this.state.esito === true ? 'SUPERATO' : 'NON SUPERATO'} color={this.state.esito === true ? 'primary' : 'error'} variant="contained" />
                                            )}
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} md={4} mt={{ xs: 2, md: 0 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Avatar sx={{ bgcolor: green[800] }}><DoneIcon /></Avatar>
                                        <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>Risposte Corrette</Typography>
                                        <Typography variant="body" >{this.state.risposteCorrette}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4} mt={{ xs: 2, md: 0 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Avatar sx={{ bgcolor: red[600] }}><CloseIcon /></Avatar>
                                        <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>Risposte Errate</Typography>
                                        <Typography variant="body" >{this.state.risposteErrate}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4} mt={{ xs: 2, md: 0 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Avatar sx={{ bgcolor: blue[700] }}><CloseIcon /></Avatar>
                                        <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>Risposte Omesse</Typography>
                                        <Typography variant="body" >{this.state.risposteOmesse}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12} sx={{ width: '100%', height: '100%' }}> {/*Grafico */}
                                    <PieGraph corrette={this.state.risposteCorrette} errate={this.state.risposteErrate} omesse={this.state.risposteOmesse} />
                                </Grid>
                            </Grid>


                        </Paper>
                        <Box sx={{ height: '70vh', mb: 4, mt: 2 }}>
                            <Virtuoso
                                style={{ height: '100%' }}
                                totalCount={this.state.listQuiz.length}
                                itemContent={index => (
                                    <RowDomanda index={index} listQuiz={this.state.listQuiz} changeColorAnswerText={this.changeColorAnswerText} />
                                )}
                            />
                        </Box>
                    </Box>
                )}
                <Box sx={{ width: '100%', flexDirection: { xs: 'column', md: 'row' }, display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 2 }}>
                    {this.state.viewingListQuiz === true && (
                        <Button variant="outlined" sx={{ mr: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }} startIcon={<ArrowBackIcon />} onClick={this.goBackToRiepilogo}>Torna al riepilogo</Button>
                    )}
                    {/* {this.state.isInRiepilogo === false && (
                        <Button sx={{ mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="contained" onClick={this.closeDialog}>Chiudi</Button>
                    )} */}
                </Box>

            </Box>

        )
    }
}


export const GoToImpostazioni = (props) => {
    const navigate = useNavigate()
    const changePage = e => {
        e.preventDefault()
        navigate("/home")
    }

    return (
        <Button sx={{ ml: 2, mt: 4, mb: 1, px: 5 }} variant="contained" onClick={changePage}>  Riprova simulatore </Button>
    )
}
