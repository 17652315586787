import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VistaQuiz from './pages/vistaQuiz';
import ErrorPage from './pages/errorPage';
import { useEffect } from 'react';
import CookiePolicy from './pages/privacyPolicy';
import DebugToken from './pages/debugToken';
import { useState } from 'react';
import SessioneScadutaPage from './pages/sessioneScadutaPage';
import { HelmetProvider } from 'react-helmet-async';
import Dashboard from './components/dashboard/dashboard';
import DashboardQuestionario from './components/impostazioni-questionario/dashboardQuestionario';
import AlertCookies from './components/shared/alertCookies';
import { SnackbarProvider } from './context/snackbarContext';
import { ManutenzioneProvider } from './context/manutenzioneContext';

export default function App() {

  const [cookieAccepted, setCookiesAccepted] = useState(true);

  useEffect(() => {
    if (!process.env.REACT_APP_IS_DEBUG) {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    } else { //Se avvio e apre "http://localhost:3001" ti riporto a simdev (o qualsiasi sia il link impostato sull' ENV)
      const currentUrl = window.location.href;
      if (currentUrl.includes("localhost:"))
        window.location.href = process.env.REACT_APP_PUBLIC_URL;
    }

    if (localStorage.getItem('cookieAccepted') != null) {
      setCookiesAccepted(true);
    } else {
      setCookiesAccepted(false);
    }
  }, [])


  function acceptCookiesMethod() {
    setCookiesAccepted(true);
    localStorage.setItem('cookieAccepted', "true");
  }

  function abilitaAlertCookie() {
    setCookiesAccepted(false);
    localStorage.setItem('cookieAccepted', "false");
  }

  return (
    <ThemeConfig>
      <HelmetProvider>
        <GlobalStyles />
        <ManutenzioneProvider>
          <SnackbarProvider>
            {/* <BrowserRouter basename={"/" + process.env.REACT_APP_PREFIX}> */}
            <BrowserRouter basename={process.env.REACT_APP_PREFIX}>
              <Routes>
                <Route index element={<Dashboard abilitaAlertCookie={abilitaAlertCookie} />} />
                <Route path="home" element={<Dashboard abilitaAlertCookie={abilitaAlertCookie} />} />
                <Route path="sim/*" element={<DashboardQuestionario />} />
                <Route path="quiz/*" element={<VistaQuiz />} />
                <Route path="sessione-scaduta" element={<SessioneScadutaPage />} />
                <Route path="token-debug/*" element={<DebugToken />} />
                <Route path="*" element={<ErrorPage />} />
                <Route path="cookie-policy" element={<CookiePolicy />} />
              </Routes>

            </BrowserRouter>
            {!cookieAccepted && (
              <AlertCookies acceptCookiesMethod={acceptCookiesMethod} />
            )}
          </SnackbarProvider>
        </ManutenzioneProvider>

      </HelmetProvider>
    </ThemeConfig >
  );
}


