import { ArrowBack, ArrowForward, ArrowRight, ClearAll, Close, Search, Tune } from "@mui/icons-material";
import { useMediaQuery, useTheme, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, Radio, RadioGroup, SwipeableDrawer, TextField, Typography, Collapse, Divider, Slide, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { SocialAPI } from "src/api/socialApi";
import { PostObj } from "src/types/questionario.types";
import Post from "./post";
import { Virtuoso } from "react-virtuoso";
import CreateNewPost from "./createNewPost";
import { useSnackbar } from "src/context/snackbarContext";

export default function Forum(props: Props) {
    const [showingFiltri, setShowingFiltri] = useState(false);
    const [isDialogListThreadOpen, setIsListThreadDialogOpen] = useState(false);
    const [isSearchingPosts, setIsSearchingPosts] = useState(false);
    const [listPosts, setListPosts] = useState<PostObj[]>([]);
    const [showOnlyMyPosts, setShowOnlyMyPosts] = useState(false);
    const [orderBy, setOrderBy] = useState("0"); // 0 = più recenti, 1 = meno recenti, 2 = più popolari
    const [offsetPagination, setOffsetPagination] = useState(0);
    const [hasMorePosts, setHasMorePosts] = useState(true);
    const [textToSearch, setTextToSearch] = useState("");
    const [previousTextToSearch, setPreviousTextToSearch] = useState("");
    const [isSearchbarCollapsed, setIsSearchbarCollapsed] = useState(true);
    const [areFiltriApplied, setAreFiltriApplied] = useState(false);
    const { showSnackbar } = useSnackbar();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (props.idQuestionario && props.idQuestionario !== -1) {
            getForum(true);
        }
    }, [props.idQuestionario]);

    function openForumDialog() {
        setIsListThreadDialogOpen(true);
        getForum(true);
    }

    function updateThreadArray(objThread: PostObj | null, index: number) {
        if (objThread) {
            setListPosts((prev) => {
                const updatedPosts = [...prev];
                updatedPosts[index] = objThread;
                return updatedPosts;
            });
        }
    }

    function postCreated(newPost: PostObj) {
        setListPosts((prevPosts) =>
            [newPost, ...prevPosts]
        );
        showSnackbar("Post creato", "success");
    }

    async function applicaTerminiDiRicerca() {
        setOffsetPagination(0);
        setPreviousTextToSearch(textToSearch);
        await getForum(true);
        setIsSearchbarCollapsed(true);
    }

    async function resetTerminiDiRicerca() {
        setOffsetPagination(0);
        setTextToSearch("");
        setPreviousTextToSearch("");
        await getForum(true, true);
        setIsSearchbarCollapsed(true);
    }

    async function applicaFiltri() {
        // setListPosts([]);
        setAreFiltriApplied(true);
        setOffsetPagination(0);
        await getForum(true);
        setShowingFiltri(false);

    }

    function resetFiltri() {
        setAreFiltriApplied(false);
        setOffsetPagination(0);
        // setTextToSearch("");
        //  setPreviousTextToSearch("");
        setOrderBy("0");
        setShowOnlyMyPosts(false)
        setTimeout(() => {
            getForum(true, false, true);
        }, 300)
    }

    async function getForum(isInitialLoad: boolean, resetTextToSearch?: boolean, resetFilters?: boolean) {
        if (!isInitialLoad && isSearchingPosts) return;
        setIsSearchingPosts(true);
        const dataObj = {
            byUser: resetFilters ? false : showOnlyMyPosts,
            ordinamento: resetFilters ? 0 : parseInt(orderBy),
            offset: isInitialLoad || resetFilters ? 0 : offsetPagination,
            ricerca: resetTextToSearch ? "" : textToSearch
        };

        try {
            const result = await SocialAPI.getForum(props.idQuestionario, dataObj);

            const threadsWithComments = result.data.threads.map((thread: any) => ({
                ...thread,
                listCommenti: [],
            }));

            if (isInitialLoad) {
                setListPosts([...threadsWithComments])
            } else {
                setListPosts((prevPosts) =>
                    isInitialLoad ? threadsWithComments : [...prevPosts, ...threadsWithComments]
                );
            }
            setOffsetPagination((prevOffset) => prevOffset + threadsWithComments.length);
            //  setPreviousTextToSearch(textToSearch);
            //  setTextToSearch("");

            if (result.data.threads.length === 0) {
                setHasMorePosts(false);
            }
        } catch (error) {
            console.error("Errore durante il caricamento del forum:", error);
        } finally {
            setIsSearchingPosts(false);
        }
    }

    return (
        <Fragment>
            <Button
                onClick={openForumDialog}
                variant="outlined"
                sx={{ mt: { xs: 1, md: 0 }, ml: { xs: 0, md: props?.isBtnFullWidth ? 0 : 2 }, width: { xs: "100%", md: props?.isBtnFullWidth ? "100%" : "fit-content" } }}
            >
                Forum
            </Button>

            <Dialog fullScreen={isMobile} fullWidth maxWidth="xl" open={isDialogListThreadOpen} onClose={() => setIsListThreadDialogOpen(false)}>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" component="span">
                            Forum
                        </Typography>
                        <IconButton onClick={() => setIsListThreadDialogOpen(false)} edge="end" aria-label="close">
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0, display: "flex", flexDirection: "column", height: "75vh" }}>

                    <Box sx={{ p: 2, borderBottom: "1px solid #ddd" }}>
                        <Box sx={{ display: "flex", alignItems: "start", gap: 2, flexWrap: { xs: "nowrap", md: "nowrap" } }}>
                            <CreateNewPost postCreated={postCreated} idQuestionario={props.idQuestionario} isSearchingPosts={isSearchingPosts} />

                            <Box sx={{ overflow: 'hidden', width: '100%' }}>
                                <Slide direction="left" in={!isSearchbarCollapsed} mountOnEnter unmountOnExit>
                                    <TextField
                                        placeholder="Il testo della ricerca deve avere almeno 3 caratteri"
                                        value={textToSearch}
                                        onChange={(e) => setTextToSearch(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && !isSearchingPosts && textToSearch.length >= 3) {
                                                applicaTerminiDiRicerca();
                                            }
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        FormHelperTextProps={{
                                            sx: { marginLeft: 0 },
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={applicaTerminiDiRicerca} disabled={isSearchingPosts || textToSearch === "" || textToSearch.length < 3}>
                                                        <ArrowForward />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Slide>
                            </Box>
                            <Box sx={{ flexShrink: 0, height: '56px', display: "flex", alignItems: "center" }}>

                                <IconButton onClick={() => setIsSearchbarCollapsed(prev => !prev)}>
                                    <Search />
                                </IconButton>

                                {areFiltriApplied && (
                                    <Button sx={{ mx: 1 }} color="error" variant="outlined" onClick={resetFiltri} disabled={isSearchingPosts || !areFiltriApplied} endIcon={<ClearAll />} >
                                        Rimuovi filtri
                                    </Button>
                                )}
                                <IconButton onClick={() => setShowingFiltri(prev => !prev)} disabled={isSearchingPosts}>
                                    <Tune />
                                </IconButton>
                            </Box>
                        </Box>
                        <Collapse in={showingFiltri}>
                            <Box >
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="h6">Filtri</Typography>
                                <Box sx={{ mt: 2, display: "flex", flexDirection: { xs: 'column', md: "row" }, alignItems: { xs: "start", md: "center" } }}>
                                    <FormControlLabel sx={{ mr: 2 }}
                                        control={
                                            <Checkbox
                                                onChange={() => setShowOnlyMyPosts((prev) => !prev)}
                                                checked={showOnlyMyPosts}
                                            />
                                        }
                                        label="Mostra solo i miei post"
                                    />
                                    <FormControl fullWidth >
                                        <FormLabel id="filter-options">Ordina per</FormLabel>
                                        <RadioGroup
                                            row
                                            value={orderBy}
                                            onChange={(e, value) => setOrderBy(value)}
                                            aria-labelledby="filter-options"
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label="più recenti" />
                                            <FormControlLabel value="1" control={<Radio />} label="meno recenti" />
                                            <FormControlLabel value="2" control={<Radio />} label="più popolari" />

                                        </RadioGroup>
                                    </FormControl>

                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "end" }}>
                                    <Button variant="contained" sx={{ mt: 2 }} onClick={applicaFiltri}>
                                        Applica
                                    </Button>
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>
                    {previousTextToSearch !== "" && (
                        <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
                            <IconButton onClick={resetTerminiDiRicerca} disabled={isSearchingPosts}>
                                <ArrowBack />
                            </IconButton>
                            <Typography>Risultati di ricerca per: <b>{previousTextToSearch}</b></Typography>
                        </Box>
                    )}
                    {listPosts.length > 0 ? (
                        <Box sx={{ flex: 1, overflow: "hidden", p: 0, position: "relative" }}>
                            <Virtuoso
                                endReached={() => {
                                    if (!isSearchingPosts && hasMorePosts) {
                                        getForum(false);
                                    }
                                }}
                                data={listPosts}
                                itemContent={(index, post) => (
                                    <Box sx={{ p: 2 }}>
                                        <Post
                                            idQuestionario={props.idQuestionario}
                                            postObj={post}
                                            threadChanged={(objThread) => updateThreadArray(objThread, index)}
                                            closeDialogForum={() => setIsListThreadDialogOpen(false)}
                                        />
                                    </Box>
                                )}
                                style={{ height: "100%", overflowY: "auto" }}
                            />
                            {isSearchingPosts && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        width: "100%",
                                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                                        textAlign: "center",
                                        py: 1,
                                        boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.2)",
                                    }}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        Caricamento nuovi post...
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ p: 2, minHeight: '50vh', display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Alert severity="info">Nessun risultato</Alert>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

interface Props {
    isBtnFullWidth?: boolean
    idQuestionario: number;
}
