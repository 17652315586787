import { createContext, ReactNode, useContext, useState } from 'react';
import { AuthAPI } from 'src/api/authApi';
import { Backdrop, Box, Link, Typography } from '@mui/material';
import logo from '../assets/edizioni-simone-logo-blu.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
// Tipo per il contesto dello Snackbar
interface ManutenzioneContextType {
    isManutenzione: boolean;
    checkManutenzione(): void;
}

// Creazione del contesto
const ManutenzioneContext = createContext<ManutenzioneContextType | undefined>(undefined);

export function ManutenzioneProvider({ children }: { children: ReactNode }) {
    const [isManutenzione, setIsManutenzione] = useState(false);
    const [msgManutenzione, setMsgManutenzione] = useState("");

    async function checkManutenzione() {
        const result = await AuthAPI.isManutenzione();
        if (result && result.success) {
            setIsManutenzione(result?.data?.isInMaintenance ?? false)
            setMsgManutenzione(result?.data?.msg ?? "")
        } else {
            setIsManutenzione(false)
            setMsgManutenzione("")
        }
    }

    return (
        <ManutenzioneContext.Provider value={{ checkManutenzione,isManutenzione  }}>
            {children}
            <Backdrop
                sx={{
                    zIndex: 9999,
                     //backgroundColor: 'linear-gradient(145deg, rgba(24,102,171,1) 0%, rgba(0,53,99,1) 80%)',
                    backgroundColor: 'rgba(255, 255, 255, 1) !important',
                    backgroundImage:"none",
                    // backgroundImage:  'linear-gradient(145deg, rgba(24,102,171,1) 0%, rgba(0,53,99,1) 80%)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 6
                }}
                open={isManutenzione}
            >
                <img unselectable="on" draggable="false" alt="Logo Simone Edizioni" width={300} src={logo}></img>


                {msgManutenzione !== "" && (
                    <Typography variant='h6'>{msgManutenzione}</Typography>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center', color: "white", gap: 3 }}>
                    <Link component="a" target="_blank" aria-label="Pagina Facebook Edizioni Simone" href="https://it-it.facebook.com/EdizioniSimone/" underline="none">
                        <FacebookIcon sx={{ fontSize: '2rem' }} />
                    </Link>
                    <Link target="_blank" aria-label="Account Instagram Edizioni Simone" href="https://www.instagram.com/edizionisimone/?hl=it" underline="none">
                        <InstagramIcon sx={{ fontSize: '2rem' }} />
                    </Link>
                    <Link target="_blank" aria-label="Canale Youtube Edizioni Simone" href="https://www.youtube.com/channel/UC7Sl8bFGpJMiilhozwnMBnQ" underline="none">
                        <YouTubeIcon sx={{ fontSize: '2.5rem' }} />
                    </Link>

                </Box>
            </Backdrop>
        </ManutenzioneContext.Provider>
    );

}

export function useManutenzioneContext(): ManutenzioneContextType {
    const context = useContext(ManutenzioneContext);
    if (!context) {
        throw new Error('useManutenzioneContext deve essere usato all interno di un ManutenzioneProvider');
    }
    return context;
}
